import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Gizmo = () => {
  const headingbanner = {
    title: `Gizmo`,
    content: `A Market Research Platform`,
  };

  const data = {
    images: ["gizmo.webp", "gizmo-2.webp"],
    firstAlt: "UI UX Dashboard To Analyze Specific Market Survey",
    firstTitle: "Survey Dashboard",
    secondAlt: "Design Components Used To Create Survey",
    secondTitle: "Design Components",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["UI UX Designing", "Rapid Prototyping", "Dashboards and Data Visualisation"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The existing landscape of the platform lacked a comprehensive and cohesive end-to-end solution that addresses the diverse needs of researchers across multiple disciplines.`,
          `Researchers often struggle to find a single platform that can seamlessly integrate all the necessary tools, resources and services required to conduct their research effectively and efficiently.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our solutions provide researchers with extensive customisation options, allowing them to tailor the platform to their specific research needs.`,
          `Researchers are now able to personalise their workspace, select and configure the tools and services they require and define workflows that suit their research methodologies and preferences.`,
        ],
      },
    ],
    image: ["gizmo-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Analyse Individual Projects",
    para: [
      `The project analysis dashboard reflects the performance of each survey and provides a detailed insight into each metric for informed decision-making.`,
    ],
    content: {
      imageAlt: 'UI UX Dashboard To Analyze Specific Market Survey',
      imageTitle: 'Survey Dashboard',
      image: ["gizmo-projectone.webp"],
      text: `We also provided an option to create new projects which can be customised based on the researcher’s needs and business requirements. `,
    },
    content2: {
      imageAlt: 'UI Screen to create new project',
      imageTitle: 'Create Project',
      image: ["gizmo-projectone-2.webp"],
      text: `This customisation option as a result assists researchers in understanding specific consumer needs, identifying the growth opportunities and making strategies aligned to your business goals. `,
    },
  };

  const projectData3 = {
    title: "Create Project",
    para: [
      `Being a market research agency, they have to facilitate clients across industries. We understood their goal and created an interface that not just define their client’s requirement of the survey but also takes into account the project-specific details of the clients.`,
      `We also provided a customisation option for researchers to create surveys according to their needs. `,
    ],
    content: {
      image: ["gizmo-projectthree.webp"],
      imageAlt: 'UI Design to create detailed project for specific requirements',
      imageTitle: 'Create Detailed Project Requirement',
    },
  };

  const conclusionData = [
    {
      para: [
        `Gizmo is a marketing research agency that caters to the needs of varied industries looking to gain user insights through surveys. `,
        `Earlier they used to manually look into the survey process, gather, gain and analyse the data for the clients which was a time-consuming process and also increases the chances of human errors.`,
        `We created a digital platform for them to facilitate the easy and effective creation of surveys that can help their clients to gather valuable insights and feedback from the audience.`,
        `With the user-friendly interface and wide range of customisation options, we made it easy for the researchers to create functional and engaging surveys.`,
        `By leveraging the power of survey builders, businesses across industries can collect the data to make informed business decisions, improve their products or services and gain a competitive edge in their respective industries. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        {/* <CommonProjectComponent data={projectData2} /> */}
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={8} />
      </div>
    </Layout>
  );
};

export default Gizmo;

export const Head = () => (
  <Seo title="Gizmo- Market Research Platform Case Study | Octet" description="A market research platform to offer real-time data interpretation and analysis. We offered services such as UI UX Designing, Rapid Prototyping, etc." />
)